<template>
    <div class="serviceCenter">
        <el-input type="textarea" resize="none" style="width:866px;padding:34px 51px" rows="15" v-model="content" placeholder="请输入您的意见反馈，我们会更好的改进"></el-input>
        <button class="aubmit cursorP" @click="submit">确认提交</button>
        <div class="tel">
            联系电话： {{this.tel}}
        </div>
    </div>
</template>
<script>
import { add_feedback, common_info } from '@/utils/Api/userList'
export default {
    name:'serviceCenter',
    data(){
        return{
            content:'',
            tel:''
        }
    },
    created(){
        this.initial()
    },
    methods:{
        async initial(){
            let res = await common_info()
            if(res){
                this.tel = res.service_phone
            }
        },
        async submit(){
            let res = await add_feedback({content: this.content})
            this.$message.success('意见提交成功！')
            this.content = ''
        }
    }
}
</script>
<style lang="scss" scoped>
.tel{
    font-size: 18px;
    margin-left: 55px;
    margin-top: 42px;
    color: #666666;
}
.serviceCenter{
    text-align: left;
    .aubmit{
        width: 112px;
        height: 33px;
        opacity: 1;
        background: #47d7e3;
        border-radius: 2px;
        font-size: 15px;
        color: #fff;
        margin-left: 55px;
    }
}
</style>